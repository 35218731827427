<template>
<div>
  <columns>
    <column>
      <text-input 
        classes="is-rounded is-medium"
        required
        v-model="number.label"
        :error="$root.errors['handler_payload.label']"
        description="The label that will be displayed during the checklist.">
        Label
      </text-input>
    </column>
    <column>
      <text-input 
        required
        classes="is-rounded is-medium"
        v-model="number.units"
        :error="$root.errors['handler_payload.units']"
        placeholder="i.e. Km/h"
        description="The unit of measurment that represents this field i.e. km/h"
        hint="wake up">
        Units
      </text-input>
    </column>
    <column>
      <number-input
        classes="has-addons is-rounded is-medium"
        :disabled="!number.meta.with_minimum"
        v-model="number.min"
        description="Checkpoint will fail if value is lower than the minimum.">
        Minimum Value
        <template #right>
          <action-button
            class="is-rounded"
            @click="number.meta.with_minimum = !number.meta.with_minimum">
            <icon type="far" :icon="number.meta.with_minimum ? 'eye' : 'eye-slash'"/>
          </action-button>
        </template>
      </number-input>
    </column>
    <column>
      <number-input 
        classes="has-addons is-rounded is-medium"
        v-model="number.max"
        :disabled="!number.meta.with_maximum"
        description="Checkpoint will fail if value is greater than the maxmimum.">
        Maximum Value
        <template #right>
          <action-button 
            class="is-rounded"
            @click="number.meta.with_maximum = !number.meta.with_maximum">
            <icon type="far" :icon="number.meta.with_maximum ? 'eye' : 'eye-slash'"/>
          </action-button>
        </template>
      </number-input>
    </column>
  </columns>  
  <p class="help is-danger has-text-right">The checkpoint will not be assessed for success or failure if no minimum or maximum are set.</p>
</div>  
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  
  props: {
    builderPayload: {
      type: [Object, Array],
      default: () => ({})
    }
  },

  data: () => ({
    number: {
      label: 'Your Input Label',
      min: '',
      max: '',
      units: '',
      meta: {
        with_minimum: true,
        with_maximum: true,
        with_units: false
      }
    }
  }),

  created() {
    if(this.checkpoint.id) {
      this.number = this.checkpoint.handler_payload
    }
    this.setHandlerPayload()
  },

  methods: {
    updateHandlerPayload() {
      this.setHandlerPayload()
    },
    setHandlerPayload() {
      this.$store.commit('checkpoint/setHandlerPayload', {...this.number})
    }
  },

  computed: {
    ...mapGetters('checkpoint', [
      'checkpoint'
    ])
  },

  watch: {
    'number.label': 'updateHandlerPayload',
    'number.min': 'updateHandlerPayload',
    'number.max': 'updateHandlerPayload',
    'number.units': 'updateHandlerPayload',
  }

}
</script>